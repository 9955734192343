/* Acumin Fonts */
@import url("https://use.typekit.net/jgy3ndt.css");

/*So that elements of a set WIDTH will have the right width even with padding*/
*{
	box-sizing:border-box;
}

body, html {
  height: 100%;
}

h2 {
  font-weight: bold !important;
  padding-bottom: 30px;
}

ul {
  list-style-type: none;
  margin: 0 !important;
  padding: 0;
}
 
a {
  text-decoration: none;
  color: #191919 !important;
}

ul li a:hover {
  text-decoration: none;
  color: #191919;
}

p {
  font-family: var(--acumin-pro);
  font-size: 18px !important;
  color: var(--bodyText);
  text-align: justify !important;
}

.icon {
  width: 50px;
  height: 50px;
}

.section_container {
  overflow: hidden;
  min-height: 100vh;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  font-size: 15px;
  font-weight: lighter;
  opacity: 1; /* Firefox */
}

/* Color Palette */
:root {
    --titleColor: #1E1C24; /* all section titles */ 
    --bodyText: #191919; /* all body text */ 
    --paleGray: #B1AAAA; /* bottom page note & right shizzle */
    --overlay: #191919; /* splash screen & realisations overlay */
    --overlay-90: #191919e6;
    --acumin-pro: acumin-pro, sans-serif;
    --discover_btn_width: 180px;
}

/*If the browser doesnt know how to handle the recent element, it will behave as a block*/
article, aside, details, figcaption, figure, footer, header, main, nav, section { display: block; }
/*Sets the bodys margins to 0*/
body {
  background-color: #191919e6;
  position: relative;
  margin: 0;
}

.center_vertically {
  position: absolute;
  top: 50vh;
  transform: perspective(1px) translateY(-50%);
}

.center_horizontally {
  right: 50vw;
  transform: perspective(1px) translateX(50%);
}

/*clearfix hack!*/
.row:after{
  content:"";
  clear:both;
  display:block;
}


@media only screen and (max-width: 992px) {

  .section_container { 
    height: unset !important;
  }
}

@media only screen and (max-width:768px) {

  .no-gutters-sm {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters-sm [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  section.section_container {
    background-repeat: no-repeat;
    background-size: cover;
    height: unset;
  }

  .container {
    max-width: unset !important;
  }

  .mobile_padding_bottom {
    padding-bottom: 50px;
  }

  .mobile_padding_top {
    padding-top: 100px;
  }
  
  #service-wrapper {
    margin-top: 0;
  }
}

/* MEDIA QUERY FOR MOBILE */
@media only screen and (max-width:640px) {
  
  .mobile_no_gutters {
    padding: 0 !important;
  }

  h2 {
    padding-bottom: 0px;
  }

  p {
    font-size: 1rem !important;
  }
}